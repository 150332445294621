@import "../../styles/_chunk";

.ts-item {
  margin-bottom: 2px;
  align-items: center;
  position: relative;
  display: none;

  @include media-breakpoint-up(lg) {
    height: 151px;
    display: block;
  }

  @include media-breakpoint-up(xl) {
    height: 156px;
  }

  @include media-breakpoint-up(xxl) {
    height: 183px;
  }

  .wishlist-container {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0 !important;
    margin-right: 1rem;
  }

  .ts-item-wrapper {
    background: $card-background-dark;
    border-radius: $border-radius;
    overflow: hidden;

    @include theme(light) {
      background: map-get($light-theme, card-background);
      box-shadow: map-get($light-theme, card-box-shadow);
    }

    .ts-number {
      display: none;
    }

    .ts-release-date {
      color: $grey-5;
      margin-bottom: 1rem;
    }

    .ts-critic-score {
      background-color: $grey-14;
      color: $text-white;
      font-weight: 700;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      width: 50px;
      font-size: 18px;
      border-radius: $border-radius-sm;
      min-height: 80px;
      margin: 1rem 0.75rem;

      @include media-breakpoint-up(sm) {
        font-size: 20px;
      }

      @include media-breakpoint-up(xl) {
        width: 100px;
        font-size: 32px;
      }

      @include theme(light) {
        background-color: $grey-2;
        color: $grey-6;
      }

      @include theme(light) {
        background-color: $grey-3;
      }

      &.metacritic {
        color: $white;

        &--green {
          background-color: $metacritic-green;

          &:hover {
            background-color: $metacritic-green !important;
          }
        }

        &--yellow {
          background-color: $metacritic-yellow;

          &:hover {
            background-color: $metacritic-yellow !important;
          }
        }

        &--red {
          background-color: $metacritic-red;

          &:hover {
            background-color: $metacritic-red !important;
          }
        }
      }
    }

    .ts-img-wrapper {
      position: relative;
      width: 100%;

      .round-label {
        top: auto;
        bottom: 0;
        margin-left: 8px;
        margin-bottom: 25px;
        margin-top: 0;

        .label-slash,
        .label-triangle {
          display: none;
        }

        .label-content {
          padding: 1px 8px;
          font-size: 10px;
          border-radius: $border-radius-lg;
        }
      }

      .infinite-loader {
        transition: opacity 0.2s linear 0.2s;
        position: absolute;
        opacity: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        width: 100%;
        top: 0;
        right: 0;
        left: 0;
        z-index: 1;

        .spinner > div {
          background-color: hsla(0, 0%, 100%, 0.6);
        }
      }

      .product-cover-container {
        .responsive-image {
          img {
            width: 100%;
          }
        }
      }

      .cover-video-container {
        video {
          width: 100%;
          height: 100%;
          position: absolute;
          border-radius: $border-radius;
        }
      }

      .screenshot-carousel {
        position: relative;
        transition: opacity 0.3s linear;
        opacity: 0;
        z-index: 8;
        border-radius: $border-radius;
        overflow: hidden;

        .screenshot-overlay {
          position: absolute;
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
          width: 100%;
          height: 100%;
          background-image: linear-gradient(180deg, transparent, rgba(0, 0, 0, 0.3));
        }

        @include theme(light) {
          background-image: linear-gradient(180deg, transparent, rgba(0, 0, 0, 0.2));
        }

        .screenshot-progress-dot-container {
          position: absolute;
          left: 0;
          right: 0;
          display: flex;
          flex: 1 1 auto;
          width: 100%;
          box-sizing: border-box;
          padding: 0 16px;

          .screenshot-progress-dot {
            width: 100%;
            height: 2.8px;
            box-sizing: border-box;
            background-color: hsla(0, 0%, 100%, 0.4);
            border-radius: 3.8px;
          }

          .active-dot {
            background-color: $white;
          }

          .screenshot-progress-dot:not(:first-child) {
            margin-left: 8px;
          }
        }

        .screenshot-progress-dot-container-small {
          bottom: 8px;
        }

        .screenshot-progress-dot-container-medium {
          bottom: 15px;
        }

        .screenshot-fallback {
          background-color: $card-background-dark;
          height: 100%;
          width: 100%;
          position: absolute;
          border-radius: $border-radius;

          @include theme(light) {
            background-color: map-get($light-theme, card-background);
            box-shadow: map-get($light-theme, card-box-shadow);
          }

          .screenshot-fallback-content {
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            position: absolute;
            display: flex;
            flex-direction: column;
            justify-content: center;
            color: $text-white;
            font-weight: 700;
            text-align: center;
            font-size: 1.2rem;
            padding-left: 3rem;
            padding-right: 3rem;

            @include theme(light) {
              color: map-get($light-theme, font-color);
            }
          }

          .screenshot-fallback-content-small {
            font-size: 1rem;
          }

          .screenshot-fallback-content-medium {
            font-size: 1.25rem;
          }
        }

        &.tall-screenshots {
          .screenshot-container {
            position: relative;
            width: 100%;
            padding-top: 56.25%; /* 16:9 Aspect Ratio */
            overflow: hidden;
            border-radius: $border-radius-lg;

            .screenshot-image {
              position: absolute;
              top: 0;
              left: 0;
              bottom: 0;
              right: 0;
              display: flex;
              justify-content: center;

              img {
                width: auto;
                height: 100%;
                border-radius: 0;
              }

              img.screenshot-bg {
                position: absolute !important;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                width: 100%;
                height: auto;
                filter: blur(20px) brightness(0.9);
                transform: scale(1.1);
              }
            }

            .screenshot-fallback {
              top: 0;
              left: 0;
            }
          }
        }
      }

      .responsive-image {
        border-radius: $border-radius;
      }

      .hitCardLabel {
        top: auto;
      }
    }

    .ts-body {
      width: 50%;
      padding-left: 0.75rem;
      padding-right: 0.75rem;

      .ts-title {
        font-size: 0.875rem;
        line-height: 1.25;
        margin-bottom: 0.25rem;

        a {
          color: $text-white;

          @include theme(light) {
            color: map-get($light-theme, font-color);
          }
        }
      }

      .ts-desc {
        color: $grey-5;
        font-size: 0.875rem;
        margin-bottom: 0.438rem;
        line-height: 1.5;
        max-height: 45px;
        overflow: hidden;

        @include media-breakpoint-up(xl) {
          max-height: 64px;
        }
      }

      .drm-container {
        margin-right: 1rem;
      }

      .dlc-badge {
        margin-left: 1rem;
      }

      .icons-container {
        display: flex;
        align-items: center;

        .card-os-icons {
          display: flex;
          font-size: 16px;

          @include theme(light) {
            color: map-get($light-theme, card-icons-color);
          }

          svg {
            margin-right: 0.5rem;

            &:last-of-type {
              margin: 0;
            }
          }
        }
      }

      .book-bundle {
        text-transform: uppercase;
        font-weight: 400;
        font-size: 12px;
        color: $text-white;

        @include theme(light) {
          color: map-get($light-theme, card-icons-color);
        }

        svg {
          margin-right: 4px;
        }
      }
    }
  }

  @include media-breakpoint-up(sm) {
    display: none;
  }

  @include media-breakpoint-up(lg) {
    margin-bottom: 1rem;
    display: block;

    .ts-item-wrapper {
      background: linear-gradient(-66deg, $card-background-dark 0%, $card-background-dark 25%, $grey-11 25%, $grey-11 100%);

      @include theme(light) {
        background: linear-gradient(-66deg, map-get($light-theme, card-background) 0%, $grey-1 25%, map-get($light-theme, card-background) 25%, map-get($light-theme, card-background) 100%);
      }

      &:hover {
        background: linear-gradient(-66deg, $grey-13 0%, $grey-13 25%, $card-background-dark 25%, $card-background-dark 100%);

        @include theme(light) {
          background: linear-gradient(-66deg, $grey-2 0%, $grey-2 25%, $grey-1 25%, $grey-1 100%);
        }
      }

      > div {
        padding: 1rem 0.75rem;
      }

      .ts-number {
        display: block;
        font-size: 2.5rem;
        font-weight: 300;
        text-align: center;
        width: 10%;
        line-height: 1;
        padding-right: 0 !important;
        flex-shrink: 0;
      }

      .ts-img-wrapper {
        width: 25%;
        flex-shrink: 0;

        img {
          border-radius: $border-radius;
        }
      }

      .ts-body {
        width: 38%;

        .ts-title {
          line-height: 1.4;
        }
      }

      .ts-pricing {
        width: 27%;
        line-height: 2;
        padding: 0 2%;

        .was-price {
          color: $grey-5;
          position: relative;
          margin-right: 1rem;
          text-decoration: line-through;
        }

        .hit-card-buttons-container {
          display: flex;
          justify-content: flex-end;
        }

        .top-seller-meta {
          margin: 0.625rem 0;
        }

        .btn {
          margin-left: 0.75rem;
          min-width: 4.75rem;
          padding: 0.475rem 0.375rem 0.5rem;
          font-size: 0.875rem;
          font-weight: 700;
          text-transform: uppercase;
          letter-spacing: 0.25px;
          text-align: center;
          flex-shrink: 0;
          display: flex;
          justify-content: center;
          align-items: center;

          svg {
            margin-right: 0.125rem;
          }

          .cart-btn-icon {
            svg {
              margin-top: -3px;
            }
          }
        }
      }
    }
  }

  @include media-breakpoint-up(xl) {
    .ts-item-wrapper {
      .ts-number {
        font-size: 3rem;
        width: 13%;
      }

      .ts-img-wrapper {
        width: 22%;
      }

      .ts-body {
        width: 36%;

        .ts-title {
          font-size: 1rem;
        }
      }

      .ts-pricing {
        width: 30%;
        padding: 0 4%;

        .top-seller-meta-pricing {
          font-size: 1rem;

          @include media-breakpoint-up(xl) {
            margin-bottom: 1rem;
          }
        }

        .show-saving {
          .card-saving {
            min-width: 60px;
          }
        }
      }
    }
  }

  .card-overlay {
    position: absolute;
    background-color: transparent;
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
    top: 0;
    right: 0;
    bottom: $hit-card-stripe-height;
    left: 0;
    text-align: center;
    transition: all ease 0.1s;
    padding: 0;

    .overlay-content {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 0;
      padding: 10px;

      .wishlist-button {
        position: absolute;
        right: 8px;
        top: 8px;
        color: $grey-8;

        button {
          margin: 0;
          padding: 0;
          width: auto;
          cursor: pointer;
        }

        button.wishlist-add {
          color: $primary-core !important;
        }

        button.wishlist-remove {
          color: $grey-8 !important;

          &:hover {
            color: $primary-core !important;
          }
        }
      }
    }
  }

  @include hover-supported() {
    .card-overlay {
      height: 100%;

      .overlay-content {
        opacity: 1;

        .wishlist-button {
          z-index: 6;
        }
      }
    }

    .card-price-container {
      &.top-seller {
        .card-saving {
          @include theme(light) {
            background-color: $grey-3;
          }
        }
      }
    }
  }
}
